import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import Header from '../components/Header'


class Profile extends React.Component {

  constructor() {
    super();

    this.state = {
      firstname: null,
      lastname: null,
      address: null,
      email: null,
      passwd: null,
      phone: null,
      children: {},
      expectations: {},
      submitOption: 1,
    };

    this.user = {};
  }

  getLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      const user = localStorage.getItem('afc-registred-user');
      if (user) {
        this.setState({ ...JSON.parse(user) })
        this.user = JSON.parse(user);
      }
    }
    return false;
  }

  componentDidMount() {
    this.getLocalStorage();
  }

  handleFirstname = (e) => {
    this.setState({ firstname: e.target.value })
  }

  handleLastname = (e) => {
    this.setState({ lastname: e.target.value })
  }

  handleAddress = (e) => {
    this.setState({ address: e.target.value })
  }

  handleEmail = (e) => {
    this.setState({ email: e.target.value })
  }

  handlePasswd = (e) => {
    this.setState({ passwd: e.target.value })
  }

  handlePhone = (e) => {
    this.setState({ phone: e.target.value })
  }

  handleChildren = (e) => {
    e.preventDefault();
    const nodes = document.querySelectorAll('.lines-inputs');
    let values = {};
    nodes.forEach((item, index) => {
      values[index] = item.querySelector('input').value
    });
    this.setState({ children: values })
  }

  addExpectation = (id) => {
    if (typeof this.state.expectations[id] === 'undefined') {
      this.setState({ expectations: { ...this.state.expectations, [id]: true} })
    } else {
      this.setState({ expectations: { ...this.state.expectations, [id]: false } })
    }
  }

  addInputLine = (e) => {
    e.preventDefault();
    const nodes = document.querySelectorAll('.lines-inputs');
    const refNode = nodes[nodes.length - 1];
    const newNode = document.createElement('div');
    newNode.className = 'lines-inputs form-group';
    newNode.innerHTML = `
    <span>Date de naissance du ${nodes.length + 1}e enfant</span>
    <input type="text" name="children[]" class="children" placeholder="__/__/____" />
    `;
    newNode.querySelector('input').onchange = this.handleChildren
    refNode.after(newNode);
  }

  handleSubmitOption = (id) => {
    this.setState({ submitOption: id })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state);
  }

  render() {

    const {
      data: { images },
      pageContext: { slug },
      location
    } = this.props;

    const pagesImages = (id) => {
      return images.edges.filter((item) => {
        return parseInt(item.node.name) === id;
      })[0].node.publicURL;
    };

    return (
      <React.Fragment>
        {/* <SEO pathname={location.pathname} banner={banners.desktop} /> */}
        <Header slug={slug} location={location} />
        <MainWrapper>
          <section className="odd">
            <div className="container">
              <h1>Inscription</h1>
            </div>
            <div className="right">
              <img src={pagesImages(7)} alt="" />
            </div>
          </section>

          <form className="register">
            <section>
              <div className="container forms-layout">
                <div className="row">
                  <div className="col-12">
                    <h2>Vous</h2>

                    <div className="form-group">
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="firstname">Prénom</label>
                          <input type="text" id="firstname" onChange={this.handleFirstname} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="firstname">Nom</label>
                          <input type="text" id="lastname" onChange={this.handleLastname} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="firstname">Adresse</label>
                      <input type="text" id="address" onChange={this.handleAddress} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstname">Email</label>
                      <input defaultValue={this.user.email} type="email" id="email" onChange={this.handleEmail} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstname">Mot de passe de votre compte</label>
                      <input type="password" id="passwd" onChange={this.handlePasswd} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Téléphone portable</label>
                      <input type="text" id="phone" onChange={this.handlePhone} />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container forms-layout">
                <div className="row">
                  <div className="col-12">
                    <h2>Votre famille</h2>
                    <div className="lines-inputs form-group">
                      <span>Date de naissance du 1er enfant</span>
                      <input type="text" name="children[]" className="children" placeholder="__/__/____" onChange={this.handleChildren} />
                    </div>
                    <div className="lines-inputs form-group">
                      <span>Date de naissance du 2e enfant</span>
                      <input type="text" name="children[]" className="children" placeholder="__/__/____" onChange={this.handleChildren} />
                    </div>
                    <div className="lines-inputs form-group">
                      <span>Date de naissance du 3e enfant</span>
                      <input type="text" name="children[]" className="children" placeholder="__/__/____" onChange={this.handleChildren} />
                    </div>
                    <button className="add-button" onClick={this.addInputLine}>+</button> Ajouter un enfant
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container forms-layout">
                <div className="row">
                  <div className="col-12">
                    <h2>Mes attentes</h2>
                    <p>Ce que vous aimeriez le plus trouver aux AFC</p>
                    <div className="buttons-group">
                      <div className="row">
                        <div className="col-4">
                          <button className={this.state.expectations[1] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(1)}}>Sorties en familles</button>
                        </div>
                        <div className="col-4">
                          <button className={this.state.expectations[2] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(2)}}>Lectures et culture</button>
                        </div>
                        <div className="col-4">
                          <button className={this.state.expectations[3] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(3)}}>Échanges entre mamans</button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <button className={this.state.expectations[4] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(4)}}>Soutien scolaire</button>
                        </div>
                        <div className="col-4">
                          <button className={this.state.expectations[5] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(5)}}>Recherche d'emploi</button>
                        </div>
                        <div className="col-4">
                          <button className={this.state.expectations[6] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(6)}}>Repas et autres aides</button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <button className={this.state.expectations[7] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(7)}}>De nouveaux amis</button>
                        </div>
                        <div className="col-4">
                          <button className={this.state.expectations[8] ? 'selected' : ''} onClick={(e) => {e.preventDefault(); this.addExpectation(8)}}>Aide pour mes ados</button>
                        </div>
                        <div className="col-4">
                          <button onClick={(e) => {e.preventDefault(); this.addExpectation(9)}} className="featured">Pouvoir aider !</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="container forms-layout">
                <div className="row">
                  <div className="col-12">
                    <h2>Je finalise</h2>
                    <div className="row">
                      <div className="col-6 featured">
                        <button className="submit-top" onClick={(e) => { e.preventDefault(); this.handleSubmitOption(1) }}>Je cotise</button>
                        <ul>
                          <li>Toutes les voix de ma famille comptent pour peser dans les actions de politique familiale en France et en local</li>
                          <li>J’ai accès à tout ce que l’AFC Bayonne-Biarritz-Anglet propose tout au long de l’année</li>
                          <li>J’ai des tarifs avantageux sur les services payants quand il y en a</li>
                          <li>Ma cotisation est valable jusqu’au 31/12 de cette année</li>
                        </ul>
                        <button className="submit-bottom" onClick={(e) => { e.preventDefault(); this.handleSubmitOption(1) }}>Je paie 30€</button>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6 regular">
                            <button className="submit-top" onClick={(e) => { e.preventDefault(); this.handleSubmitOption(2) }}>Je sympathise</button>
                            <ul>
                              <li>J’ai accès à la plupart des choses que l’AFC Bayonne-Biarritz-Anglet propose tout au long de l’année</li>
                            </ul>
                            <button className="submit-bottom" onClick={(e) => { e.preventDefault(); this.handleSubmitOption(2) }}>C’est déjà bien !</button>
                          </div>
                          <div className="col-6 regular">
                            <button className="submit-top" onClick={(e) => { e.preventDefault(); this.handleSubmitOption(3) }}>Je donne</button>
                            <ul>
                              <li>Je veux soutenir les actions de l’AFC Bayonne-Biarritz-Anglet.</li>
                              <li>Déduction fiscale à hauteur de 66%.</li>
                              <li>Reçu fiscal envoyé par email pour vos impôts</li>
                            </ul>
                            <button className="submit-bottom" onClick={(e) => { e.preventDefault(); this.handleSubmitOption(3) }}>Je donne !</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="submit" onClick={this.handleSubmit}>Je valide</button>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </MainWrapper>
      </React.Fragment>
    )
  }
}

export default Profile

export const pageQuery = graphql`
  query profilePage {
    images: allFile {
      edges {
        node {
          id
          publicURL
          name
        }
      }
    }
  }
`;